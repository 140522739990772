import React from 'react';

// loader style
const loaderStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2001,
    width: '100%',
};

// ==============================|| Loader ||============================== //

const Loader = () => (
    <div style={loaderStyles} className="bg-gray-800">
        <div className="h-2">
            <div className="h-2 bg-blue-500 animate-pulse" style={{ width: '100%' }}></div>
        </div>
    </div>
);

export default Loader;