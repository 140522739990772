import { lazy } from 'react';
import Loadable from 'component/Loadable';
import MainLayout from 'layout/MainLayout';

const MainPage = Loadable(lazy(() => import('page/MainPage')));

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <MainPage />
        }
    ]
};

export default MainRoutes;
